<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Users") }}</label>
    </div>
    <!-- Start Dialog Delete -->
    <v-dialog v-model="dialogDelete" :width="config.deleteModalWidth">
      <v-card>
        <v-toolbar color="third" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            {{ $t("AreYouSureYouWantToDelete") }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="dialogDelete = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="deleteItemConfirm"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-toolbar flat>
      <v-toolbar-title>{{ $t(config.tableName) }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn color="info" @click="newData" class="mb-2" small>
        <span class="addNewLabel">{{ $t("AddNew") }}</span>

        <v-icon class="my-0 mr-2 ml-2" dense>mdi-file</v-icon>
      </v-btn>
      <div></div>
    </v-toolbar>

    <!-- Start DataTable -->
    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      :items-per-page="table.itemsPerPage"
      :page.sync="table.page"
      hide-default-footer
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.signature="{ item }">
        <a v-if="item.signature" target="_blank" :href="item.signature">
          <img
            style="border-radius: 50%; margin: 0 5px"
            class="mr-2 mt-2"
            width="50"
            height="50"
            :src="item.signature"
            alt=""
          />
        </a>
      </template>

        <template v-slot:item.signature_text="{ item }">
          <img v-if="item.signature_text"
            style="margin: 0 5px"
            class="mr-2 mt-2"
            width="250"
            height="50"
            :src="item.signature_text?'data:image/png;base64,' + item.signature_text : '' "
            alt=""
          />
      </template>

      <template v-slot:item.actions="{ item }">
        <v-file-input
          style="display: inline-block; position: relative; top: 5px"
          dense
          hide-input
          class="my-0 mr-2 ml-2"
          prepend-icon="mdi-camera"
          accept="image/*"
          @change="uploadImage($event, item.id)"
        ></v-file-input>

        <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="
            $router.push({ name: 'userAddEdit', params: { id: item.id } })
          "
          color="success"
          >mdi-pencil</v-icon
        >

        <v-icon
          v-if="item.id !== 1"
          class="my-0 mr-2 ml-2"
          dense
          @click="
            formData = Object.assign({}, item);
            dialogDelete = true;
          "
          color="danger"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <!-- End DataTable -->

    <!-- Start Pagination -->
    <div class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div>

    <!-- End Pagination -->
  </div>
</template>

<script>
export default {
  name: "users",
  data() {
    return {
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      filtersItems: [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: 1 },
        { text: this.$t("InActive"), value: 0 },
      ],
      config: {
        tableName: "UsersTable",
        crudHeader: "Users",
        crudApi: "user",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        itemExport: {
          "#": "id",
          الاسم: {
            field: "name",
            callback: (item) => {
              if (item) return item;
            },
          },
          "البريد الإلكتروني": {
            field: "email",
            callback: (item) => {
              if (item) return item;
            },
          },
          الأدوار: {
            field: "roles",
            callback: (item) => {
              if (item) return item;
            },
          },
        },
        headers: [
          { text: "#", value: "id", width: "5rem" },
          {
            text: this.$t("Name"),
            value: "name",
            width: "10rem",
          },
          {
            text: this.$t("Email"),
            value: "email",
            width: "8rem",
          },
          {
            text: this.$t("Signature text"),
            value: "signature_text",
            width: "8rem",
          },
          {
            text: this.$t("Signature"),
            value: "signature",
            width: "8rem",
          },
          // {
          //   text: this.$t("Roles"),
          //   value: "roles",
          //   width: "8rem",
          // },
        ],
      },
      formData: {
        id: 0,
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        roles: [],
      },
      drawer: false,
      dialog: false,
      dialogDelete: false,
      roleList: [
        {
          id: 1,
          name: "constant",
        },
        {
          id: 2,
          name: "store",
        },
        {
          id: 3,
          name: "orders",
        },
        {
          id: 4,
          name: "reports",
        },
        {
          id: 5,
          name: "users",
        },
        {
          id: 6,
          name: "imports",
        },
      ],
    };
  },
  methods: {
    uploadImage(file, id) {
      let sendData = {
        signature: file,
        id: id,
      };
      this.$store.dispatch(`user/uploadImage`, sendData).then((res) => {
        this.notifySuccess("Upload", "Upload Successfully");
        this.getDataFromApi();
      });
    },
    loadPDF() {
      window.open(`/#/reports/${this.config.crudApi}`);
    },
    newData() {
      // this.clearData();
      this.$router.push({ name: "userAddEdit" });
    },
    clearData() {
      this.formData = {
        id: 0,
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        roles: [],
      };
      this.$validator.reset();
    },
    deleteItemConfirm() {
      this.deleteData(
        `${this.config.crudApi}/removeData`,
        this.formData.id
      ).then(() => {
        this.getDataFromApi();
        this.dialogDelete = false;
      });
    },
    updateStatus(item) {
      item.isLoading = true;
      this.updateStatusData(`${this.config.crudApi}/updateStatus`, item.id)
        .then(() => {
          this.getDataFromApi();
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    closeModal() {
      this.formData.id = 0;
      this.dialog = false;
      this.$validator.reset();
    },
    save() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.saveUpdateData(
          `${this.config.crudApi}/saveData`,
          `${this.config.crudApi}/updateData`,
          this.formData
        ).then(() => {
          this.getDataFromApi();
          this.closeModal();
        });
      });
    },
    getDataFromApi(options) {
      this.table.loading = true;
      this.getFrontEndData(`${this.config.crudApi}/getData`, options)
        .then((res) => {
          this.table.items = res.resources;
          this.table.length = Math.ceil(
            res.pagination.total / res.pagination.per_page
          );
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    changePage(page) {
      let options = {
        page: page,
      };
      this.getDataFromApi(options);
    },
  },
  mounted() {
    this.getDataFromApi();

    // if (
    //   (this.user &&
    //     this.user.permissions &&
    //     this.user.permissions.includes("update_admin")) ||
    //   (this.user &&
    //     this.user.permissions &&
    //     this.user.permissions.includes("delete_admin"))
    // )
    this.table.headers.push({
      text: this.$t("Actions"),
      value: "actions",
      sortable: false,
      align: "center",
      width: "8rem",
    });
  },
};
</script>